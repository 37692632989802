<template>
  <div>
    <b-tabs pills>
      <b-tab title="Tomadores" v-if="acesso.RelatorioCadastroTomadores">
        <b-card-text>
          <relatorioCadTomador />
        </b-card-text>
      </b-tab>
      <b-tab title="Usuários" v-if="acesso.RelatorioCadastroUsuarios">
        <b-card-text>
          <relatorioCadUsuario />
        </b-card-text>
      </b-tab>
    </b-tabs>
    <b-card class="text-center" v-if="todosFalsos">
      <b-card-body>
        <b-icon icon="exclamation-triangle" variant="danger" font-scale="2"></b-icon>
        <b-card-title class="text-danger mt-2">Acesso Negado!</b-card-title>
        <b-card-text class="mb-1">Parece que seu usuário não tem permissão para acessar abas deste módulo.</b-card-text>
        <b-card-text class="mb-1"
          >Para obter acesso, por favor, solicite assistência ou permissões adicionais ao administrador do sistema.</b-card-text
        >
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
  import relatorioCadastro from './relatorioCadastro.vue'
  import relatorioCadTomador from './relatorioCadTomador.vue'
  import relatorioCadUsuario from './relatorioCadUsuario.vue'
  import relatorioCadOrgao from './relatorioCadOrgao.vue'
  import ToastificationContent from '@/@core/components/toastification/ToastificationContent.vue'

  export default {
    components: {
      relatorioCadastro,
      relatorioCadTomador,
      relatorioCadUsuario,
      relatorioCadOrgao,
      ToastificationContent,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        acesso: {
          RelatorioCadastroTomadores: false,
          RelatorioCadastroUsuarios: false,
        },
      }
    },
    computed: {
      todosFalsos() {
        return Object.values(this.acesso).every((valor) => valor === false)
      },
    },

    mounted() {
      if (this.userData.role == 'master') {
        this.acesso.RelatorioCadastroTomadores = true
        this.acesso.RelatorioCadastroUsuarios = true
      } else {
        this.acesso.RelatorioCadastroTomadores = this.userData.GrupoAcesso.RelatorioCadastroTomadores
        this.acesso.RelatorioCadastroUsuarios = this.userData.GrupoAcesso.RelatorioCadastroUsuarios
      }
    },
  }
</script>
