<template>
  <div>
    <!-- FILTROS -->
    <b-card>
      <h2>Filtros</h2>
      <validation-observer ref="formPesquisar">
        <b-form class="mt-2">
          <!-- Filtros Simples -->
          <b-row>
            <b-col md="2">
              <b-form-group label="Tipo de filtro" label-for="tipoFiltroSelecionado">
                <v-select-pt
                  id="tipoFiltroSelecionado"
                  :clearable="false"
                  v-model="tipoFiltroSelecionado"
                  :options="tipoFiltro"
                  label="name"
                  class="tw-w-full tw-max-w-md tw-mb-4 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-sm:text-sm"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Pesquisar Por:" label-for="pesquisarPor">
                <v-select-pt
                  id="pesquisarPor"
                  :clearable="false"
                  v-model="pesquisarPor"
                  :options="tipoPesquisa"
                  label="name"
                  class="tw-w-full tw-max-w-md tw-mb-4 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-sm:text-sm"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Descrição" label-for="pesquisar">
                <cleave
                  v-if="pesquisarPor.name == 'CPF'"
                  id="pesquisar"
                  type="text"
                  v-model="descricao"
                  placeholder="CPF"
                  class="form-control cleave-height"
                  :options="options.cleaveCPF"
                  :raw="false"
                />
                <b-form-input
                  v-if="pesquisarPor.name == 'Nome'"
                  id="pesquisar"
                  type="text"
                  v-model="descricao"
                  placeholder="Nome"
                  :raw="false"
                />
                <b-form-input
                  v-if="pesquisarPor.name == 'Matricula'"
                  id="pesquisar"
                  type="number"
                  v-model="descricao"
                  placeholder="Matricula"
                  :raw="false"
                />
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Data Inicial" label-for="dataInicial">
                <validation-provider #default="{ errors }" name="Data Inicial">
                  <b-input-group>
                    <cleave
                      id="dataInicial"
                      type="text"
                      v-model="dataInicial"
                      placeholder="DD/MM/AAAA"
                      class="form-control cleave-height"
                      :options="options.cleaveDate"
                      :raw="false"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        size="sm"
                        button-variant="primary"
                        class="datePickerWithInput"
                        right
                        button-only
                        v-model="dataInicialDatePicker"
                        :hide-header="true"
                        :initial-date="new Date()"
                        :state="errors[0] ? false : null"
                        label-help=""
                        label-calendar="Calendário"
                        label-close-button="Fechar"
                        label-current-month="Mês atual"
                        label-next-decade="Próxima década"
                        label-next-month="Próximo mês"
                        label-next-year="Próximo ano"
                        label-prev-decade="Década anterior"
                        label-prev-month="Mês anterior"
                        label-prev-year="Ano anterior"
                        label-select-month="Selecione o mês"
                        label-select-year="Selecione o ano"
                        label-selected="Selecionado"
                        label-no-date-selected="Nenhuma data selecionada"
                        label-reset-button="Resetar"
                        label-today-button="Hoje"
                        label-today="Hoje"
                        locale="pt-br"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                        aria-controls="dataInicial"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Data Final" label-for="dataFinal">
                <validation-provider
                  #default="{ errors }"
                  name="Data Inicial"
                  :rules="{
                    dataPassada: {
                      value: dataFinal,
                      dataParametro: dataInicial,
                      stringParametro: 'data inicial',
                    },
                  }"
                >
                  <b-input-group>
                    <cleave
                      id="dataFinal"
                      type="text"
                      v-model="dataFinal"
                      placeholder="DD/MM/AAAA"
                      class="form-control cleave-height"
                      :options="options.cleaveDate"
                      :raw="false"
                    />
                    <b-input-group-append>
                      <b-form-datepicker
                        size="sm"
                        button-variant="primary"
                        class="datePickerWithInput"
                        right
                        button-only
                        v-model="dataFinalDatePicker"
                        :hide-header="true"
                        :initial-date="new Date()"
                        :state="errors[0] ? false : null"
                        label-help=""
                        label-calendar="Calendário"
                        label-close-button="Fechar"
                        label-current-month="Mês atual"
                        label-next-decade="Próxima década"
                        label-next-month="Próximo mês"
                        label-next-year="Próximo ano"
                        label-prev-decade="Década anterior"
                        label-prev-month="Mês anterior"
                        label-prev-year="Ano anterior"
                        label-select-month="Selecione o mês"
                        label-select-year="Selecione o ano"
                        label-selected="Selecionado"
                        label-no-date-selected="Nenhuma data selecionada"
                        label-reset-button="Resetar"
                        label-today-button="Hoje"
                        label-today="Hoje"
                        locale="pt-br"
                        :date-format-options="{ year: 'numeric', month: '2-digit', day: 'numeric' }"
                        aria-controls="dataFinal"
                      />
                    </b-input-group-append>
                  </b-input-group>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Filtros Avançados -->
          <b-row v-if="tipoFiltroSelecionado.name == 'Avançado'">
            <b-col md="4">
              <b-form-group label="Órgão" label-for="orgao">
                <validation-provider #default="{ errors }" name="Órgão">
                  <v-select-pt
                    name="orgao"
                    v-model="orgao"
                    :options="listaOrgaos"
                    :reduce="(option) => option.id"
                    label="nome"
                  ></v-select-pt>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Remuneração até:" label-for="valorRemuneracao">
                <b-input-group>
                  <b-input-group-prepend>
                    <span class="input-group-text">R$</span>
                  </b-input-group-prepend>
                  <b-form-input
                    id="valorRemuneracao"
                    class="form-control cleave-height"
                    placeholder="Valor"
                    v-model="remuneracao"
                    v-number="number"
                    :rules="{
                      valor: Number(remuneracao).toFixed(2),
                      zero: [],
                    }"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="2">
              <b-form-group label="Margem Disponível até:" label-for="margemDisponivel">
                <b-input-group>
                  <b-input-group-prepend>
                    <span class="input-group-text">R$</span>
                  </b-input-group-prepend>
                  <b-form-input
                    id="margemDisponivel"
                    placeholder="Valor"
                    v-model="margem"
                    v-number="margem"
                    :rules="{
                      valor: Number(margem).toFixed(2),
                      zero: [],
                    }"
                  />
                </b-input-group>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Categoria" label-for="categoria">
                <v-select-pt
                  id="categoria"
                  :clearable="false"
                  v-model="categoria"
                  multiple
                  :options="categoriaOpcoes"
                  label="name"
                  class="tw-w-full tw-max-w-md tw-mb-4 tw-rounded-md tw-shadow-sm tw-focus:outline-none tw-focus:ring-2 tw-focus:ring-blue-500 tw-focus:border-blue-500 tw-sm:text-sm"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <!-- Botões -->
          <b-row align-h="end">
            <b-col md="auto">
              <b-button
                :disabled="isBusy"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                style="margin-top: 21px; width: 160px"
                @click="carregarGrid()"
              >
                {{ !isBusy ? 'Prévia' : '' }}
                <b-spinner small v-if="isBusy" />
              </b-button>
            </b-col>
            <b-col md="auto">
              <template>
                <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                  <template #button-content>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      style="margin-top: 21px; width: 160px"
                    >
                      Exportar
                    </b-button>
                  </template>
                  <b-dropdown-item href="#" @click="exportToPDF()">
                    <feather-icon icon="FileTextIcon" size="16" />
                    <span class="align-middle ml-50" title="Exportar para PDF">PDF</span>
                  </b-dropdown-item>
                  <b-dropdown-item href="#" @click="exportToExcel()">
                    <feather-icon icon="CheckCircleIcon" size="16" />
                    <span class="align-middle ml-50" title="Exportar para excel">Excel</span>
                  </b-dropdown-item>
                </b-dropdown>
              </template>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-card>
    <!-- TABELA -->
    <b-card v-if="this.itemsTabela.length > 0 || isBusy">
      <template v-if="isBusy">
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando Tabela...</strong>
        </div>
      </template>
      <!-- TOTALIZADORES -->
      <div v-if="!isBusy" v-show="this.itemsTabela.length > 0">
        <b-row>
          <b-col md="2">
            <p>
              Total de Tomadores: <b-badge variant="primary"> {{ totalRows }}</b-badge>
            </p>
          </b-col>
        </b-row>
        <b-row>
          <b-col md="12">
            <!-- GRID -->
            <div ref="tableTomador" :tipoFiltroSelecionado="tipoFiltroSelecionado" :itemsTabela="this.itemsTabela">
              <b-table
                style="overflow-x: auto; -webkit-overflow-scrolling: touch"
                outlined
                id="tabelaTomadores"
                ref="tabelaTomadores"
                striped
                :per-page="perPage"
                :current-page="currentPage"
                :fields="currentFields"
                :items="itemsTabela"
                responsive="sm"
                class="position-relative custom-rounded-table"
                :sort-by.sync="sortBy"
                :sort-desc.sync="sortDesc"
                :sort-direction="sortDirection"
              >
                <template v-slot:cell(categoria)="row">
                  <b-badge v-if="row.item.categoria == 'Efetivo'" variant="success">{{ row.item.categoria }}</b-badge>
                  <b-badge v-else-if="row.item.categoria == 'Estavel'" variant="primary">{{ row.item.categoria }}</b-badge>
                  <b-badge v-else-if="row.item.categoria == 'Pensionista'" variant="warning">{{ row.item.categoria }}</b-badge>
                  <b-badge v-else variant="danger">Aposentado</b-badge>
                </template>
              </b-table>
              <b-card-body v-if="this.itemsTabela.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
                <!-- page length -->
                <b-form-group
                  style="align-items: center"
                  label="Por Página"
                  label-cols="6"
                  label-align="left"
                  label-size="sm"
                  label-for="sortBySelect"
                  class="text-nowrap mb-md-0 mr-1"
                >
                  <b-form-select id="perPageSelect" v-model="perPage" size="sm" inline :options="pageOptions" />
                </b-form-group>

                <!-- pagination -->
                <b-pagination
                  first-number
                  last-number
                  v-model="currentPage"
                  :total-rows="totalRows"
                  :per-page="perPage"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mb-0"
                >
                  <template #prev-text>
                    <feather-icon icon="ChevronLeftIcon" size="18" />
                  </template>
                  <template #next-text>
                    <feather-icon icon="ChevronRightIcon" size="18" />
                  </template>
                </b-pagination>
              </b-card-body>
            </div>
          </b-col>
        </b-row>
      </div>
      <div>
        <relatorioCadTomadorRetrato ref="exportarPDF" :parametro="parametroPDF" />
      </div>
    </b-card>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarAposentadoInativo, formatarData, formatarValorReais } from '@/libs/utils'
  import { generateAndDownloadPdf } from '@/utils/geradorPDF/geradorPDF.js'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import { dataPassada, required, valor } from '@validations'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  import Cleave from 'vue-cleave-component'
  import VueHtml2pdf from 'vue-html2pdf'
  import Ripple from 'vue-ripple-directive'
  import { VueSelect } from 'vue-select'
  import * as XLSX from 'xlsx'
  import relatorioCadTomadorRetrato from './components/relatorioCadTomadorRetrato.vue'

  export default {
    components: {
      valor,
      VueHtml2pdf,
      ToastificationContent,
      formatarData,
      formatarValorReais,
      generateAndDownloadPdf,
      formatarValorReais,
      relatorioCadTomadorRetrato,
      useJwt,
      dataPassada,
      formatarData,
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      Cleave,
      VueSelect,
      required,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        itemsTabela: [],
        valor: 0,
        isBusy: false,
        nomeRelatorio: 'Relatório de Cadastro de Tomadores',
        orgao: null,
        descricao: '',
        pesquisarPor: { id: 1, name: 'Nome' },
        remuneracao: null,
        margem: null,
        categoria: [],
        listaOrgaoes: [],
        parametroPDF: {
          dados: [],
          colunas: [],
        },
        linhasTabela: [],
        datePicker: {
          dataInicial: '',
          dataFinal: '',
        },
        currentPage: 1,
        perPage: 10,
        pageOptions: [10, 25, 50, 100],
        totalRows: 0,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        categoriaOpcoes: ['Efetivo', 'Estavel', 'Pensionista', 'Aposentado'],
        tipoFiltroSelecionado: { name: 'Simples' },
        tipoFiltro: [
          { id: 1, name: 'Simples' },
          { id: 2, name: 'Avançado' },
        ],
        tipoPesquisa: [
          { id: 1, name: 'Nome' },
          { id: 2, name: 'CPF' },
          { id: 3, name: 'Matricula' },
        ],
        dataInicialDatePicker: '',
        dataFinalDatePicker: '',
        dataInicial: null,
        dataFinal: null,
        options: {
          cleaveDate: {
            date: true,
            delimiter: '/',
            datePattern: ['d', 'm', 'Y'],
          },
          cleaveCPF: {
            numericOnly: true,
            delimiters: ['.', '.', '-'],
            blocks: [3, 3, 3, 2],
          },
        },
        listaOrgaos: [],
        number: {},
        colunasTabelas: [],
        simpleFields: [
          {
            key: 'nome',
            label: 'Nome',
            sortable: true,
          },
          {
            key: 'cpf',
            label: 'CPF',
            sortable: true,
          },
          {
            key: 'matricula',
            label: 'Matrícula',
            sortable: true,
          },
          {
            key: 'categoria',
            label: 'Categoria',
            sortable: true,
          },
          {
            key: 'dataNascimento',
            label: 'Data de Nascimento',
            sortable: true,
            formatter: (value) => {
              return formatarData(value)
            },
          },
          {
            key: 'sexo',
            label: 'Sexo',
            sortable: true,
          },
          {
            key: 'dataAdmissao',
            label: 'Data de Admissão',
            sortable: true,
            formatter: (value) => {
              return formatarData(value)
            },
          },
        ],
        advancedFields: [
          {
            key: 'nome',
            label: 'Nome',
            sortable: true,
          },
          {
            key: 'cpf',
            label: 'CPF',
            sortable: true,
          },
          {
            key: 'matricula',
            label: 'Matrícula',
            sortable: true,
          },
          {
            key: 'categoria',
            label: 'Categoria',
            sortable: true,
          },
          {
            key: 'remuneracao',
            label: 'Remuneração',
            sortable: true,
          },
          {
            key: 'margem',
            label: 'Margem',
            sortable: true,
          },
          {
            key: 'orgao',
            label: 'Órgão',
            sortable: true,
          },
        ],
        currentFields: [],
      }
    },
    mounted() {
      this.atualizarCampos()

      if (this.userData.role !== 'master') {
        this.institutoId = this.userData.institutoId
      } else {
        this.institutoId = this.userData.institutoSelecionado
      }
      this.carregarOrgaos()
    },
    methods: {
      atualizarCampos() {
        if (this.tipoFiltroSelecionado.name === 'Simples') {
          this.currentFields = this.simpleFields
        } else if (this.tipoFiltroSelecionado.name === 'Avançado') {
          this.currentFields = this.advancedFields
        }
      },
      carregarGrid() {
        this.isBusy = true
        this.totalRows = 0
        this.currentPage = 1

        let filtro = {
          institutoId: this.institutoId,
          dataInicial: this.dataInicial ?? null,
          dataFinal: this.dataFinal ?? null,
          orgao: this.orgao ?? null,
          remuneracao: this.remuneracao ?? null,
          margem: this.margem ?? null,
          categoria: this.categoria.map((cat) => formatarAposentadoInativo(cat)) ?? null,
          descricao: this.descricao ?? null,
          pesquisarPor: this.pesquisarPor.id ?? null,
        }
        useJwt
          .post('cadastro/tomador/GetRelatorioTomador', filtro)
          .then((response) => {
            if (!response.data.length > 0) {
              this.$toast(
                {
                  component: ToastificationContent,
                  props: {
                    title: 'Alerta!',
                    text: 'Não há dados para o filtro inserido!',
                    icon: 'BellIcon',
                    variant: 'danger',
                  },
                },
                {
                  position: 'top-right',
                  timeout: 8000,
                },
              )
              this.itemsTabela = []
              return
            }

            this.itemsTabela = response.data.flatMap((item) => {
              return item.segurado.length > 0
                ? item.segurado.map((segurado) => ({
                    categoria: segurado.situacaoPrevidenciaria || '',
                    dataAdmissao: formatarData(segurado.dataAdmissao) || '',
                    dataNascimento: formatarData(item.dataNascimento),
                    sexo: item.sexo === 'F' ? 'Feminino' : 'Masculino',
                    nome: item.nomeCompleto,
                    cpf: item.cpf,
                    matricula: segurado.matricula || '',
                    orgao: segurado.orgao ? segurado.orgao.nome : '',
                    remuneracao: formatarValorReais(segurado.remuneracao) || '0,00',
                    margem: formatarValorReais(segurado.margemDisponivel) || '0,00',
                  }))
                : [
                    {
                      categoria: '',
                      dataAdmissao: '',
                      dataNascimento: formatarData(item.dataNascimento),
                      sexo: item.sexo === 'F' ? 'Feminino' : 'Masculino',
                      nome: item.nomeCompleto,
                      cpf: item.cpf,
                      matricula: '',
                      orgao: '',
                      remuneracao: '0,00',
                      margem: '0,00',
                    },
                  ]
            })

            this.totalRows = this.itemsTabela.length

            let items = response.data.map((item) => ({
              ...item,
              segurado: [
                {
                  ...item.segurado[0],
                  situacaoPrevidenciaria: item.segurado[0]?.situacaoPrevidenciaria ?? '',
                },
              ],
            }))

            this.items = items
            this.linhasTabela = this.itemsTabela
            ;(this.colunasTabelas = this.currentFields.map((item) => ({
              label: item.label,
              key: item.key,
            }))),
              // Monta variável global de relatório PDF
              (this.parametroPDF = {
                dados: this.linhasTabela,
                colunas: this.colunasTabelas,
                institutoNome: this.userData.Instituto.nome,
              })
          })
          .catch((error) => {
            console.error(error)
            this.isBusy = false
          })
          .finally(() => {
            this.isBusy = false
          })
      },
      carregarOrgaos() {
        if (this.userData.institutoSelecionado) {
          useJwt
            .get(`cadastro/orgao/buscaPorInstitutoId/${this.userData.institutoSelecionado}`)
            .then((response) => {
              this.listaOrgaos = response.data
            })
            .catch((error) => {
              console.error(error)
            })
        }
      },
      exportToPDF() {
        if (!this.itemsTabela.length > 0) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para exportar!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }
        if (this.$refs.tableTomador) {
          let htmlContent = this.$refs.exportarPDF.$children[0].$refs.html2Pdfportrait.$el.innerHTML
          let nomeInstituto = this.userData.Instituto.nome
          let periodoFiltro = `Período: ${this.dataInicial} a ${this.dataFinal}`
          let nomeRelatorio = this.nomeRelatorio
          let informacoesInstituto = `
                    ${this.userData.Instituto.logradouro},
                    ${this.userData.Instituto.numero},
                    ${this.userData.Instituto.bairro},
                    ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf}
                    E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}
                  `
          generateAndDownloadPdf(
            this,
            htmlContent,
            nomeInstituto,
            periodoFiltro,
            nomeRelatorio,
            informacoesInstituto,
            null,
            this.userData.Instituto.id,
          )
        } else {
          console.error('Elemento da tabela não encontrado')
        }
      },
      exportToExcel() {
        if (!this.itemsTabela.length) {
          this.$toast(
            {
              component: ToastificationContent,
              props: {
                title: 'Alerta!',
                text: 'Não há dados para exportar!',
                icon: 'BellIcon',
                variant: 'danger',
              },
            },
            {
              position: 'top-right',
              timeout: 8000,
            },
          )
          return
        }

        // Usando os dados e colunas para criar a planilha
        const ws = XLSX.utils.json_to_sheet(this.itemsTabela)

        const wb = XLSX.utils.book_new()
        XLSX.utils.book_append_sheet(wb, ws, 'Relatorio')

        // Salvando o arquivo
        XLSX.writeFile(wb, 'Relatorio.xlsx')
      },
    },
    watch: {
      'tipoFiltroSelecionado.name': function (newVal) {
        this.atualizarCampos()
        this.itemsTabela = []
      },
      dataInicialDatePicker(newVal) {
        this.dataInicial = formatarData(this.dataInicialDatePicker)
      },
      dataFinalDatePicker(newVal) {
        this.dataFinal = formatarData(this.dataFinalDatePicker)
      },
    },
  }
</script>
