<template>
  <b-card style="overflow-x: auto">
    <h2>Relatório de cadastro dos órgãos</h2>
    <validation-observer ref="formPesquisar">
      <b-form>
        <b-row>
          <b-col md="3">
            <b-form-group label="Data Inicial" label-for="dataInicial">
              <validation-provider #default="{ errors }" name="Data Inicial" rules="required">
                <cleave
                  id="dataInicial"
                  class="form-control"
                  type="text"
                  placeholder="DD/MM/AAAA"
                  v-model="pesquisar.dataInicial"
                  :options="cleaveDate"
                  :raw="false"
                  v-on:keydown.enter="goPesquisar()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-form-group label="Data Final" label-for="dataFinal">
              <validation-provider #default="{ errors }" name="Data Final" rules="required">
                <cleave
                  id="dataFinal"
                  class="form-control"
                  type="text"
                  placeholder="DD/MM/AAAA"
                  v-model="pesquisar.dataFinal"
                  :options="cleaveDate"
                  :raw="false"
                  v-on:keydown.enter="goPesquisar()"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
          <b-col md="3">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              style="margin-top: 20px"
              @click="goPesquisar()"
            >
              Pesquisar
            </b-button>
          </b-col>
          <b-col md="2">
            <template>
              <b-dropdown variant="link" no-caret toggle-class="p-0" left :popper-opts="{ positionFixed: true }">
                <template #button-content>
                  <b-button v-ripple.400="'rgba(255, 255, 255, 0.15)'" variant="primary" style="margin-top: 20px">
                    Exportar
                  </b-button>
                </template>
                <b-dropdown-item href="#" @click="exportToPDF()">
                  <feather-icon icon="FileTextIcon" size="16" />
                  <span class="align-middle ml-50">PDF</span>
                </b-dropdown-item>
                <b-dropdown-item href="#" @click="exportToExcel()">
                  <feather-icon icon="CheckCircleIcon" size="16" />
                  <span class="align-middle ml-50" title="Exportar tabela para excel">Excel</span>
                  <!-- <span class="align-middle ml-50">Excel</span> -->
                </b-dropdown-item>
              </b-dropdown>
            </template>
          </b-col>
        </b-row>
      </b-form>
    </validation-observer>
    <span v-show="items.length > 0"
      >Total encontrado:
      <b-badge pill>
        {{ totalRows }}
      </b-badge>
    </span>
    <b-table
      v-show="items.length > 0"
      striped
      responsive
      class="position-relative"
      :per-page="pesquisar.perPage"
      :items="items"
      :fields="fields"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      :busy="isBusy"
      :current-page="pesquisar.currentPage"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>
      <template #cell(createdAt)="row">
        {{ formatarData(row.item.createdAt) }}
      </template>
      <template #cell(role)="row">
        <b-badge :variant="role[0][row.item.role]">
          {{ row.item.role }}
        </b-badge>
      </template>
    </b-table>
    <b-card-body v-if="items.length > 0" class="d-flex justify-content-between flex-wrap pt-0">
      <!-- page length -->
      <b-form-group
        style="align-items: center"
        label="Por Página"
        label-cols="6"
        label-align="left"
        label-size="sm"
        label-for="sortBySelect"
        class="text-nowrap mb-md-0 mr-1"
      >
        <b-form-select id="perPageSelect" v-model="pesquisar.perPage" size="sm" inline :options="pageOptions" />
      </b-form-group>

      <!-- pagination -->
      <b-pagination
        v-model="pesquisar.currentPage"
        :total-rows="totalRows"
        :per-page="pesquisar.perPage"
        first-number
        last-number
        prev-class="prev-item"
        next-class="next-item"
        class="mb-0"
      >
        <template #prev-text>
          <feather-icon icon="ChevronLeftIcon" size="18" />
        </template>
        <template #next-text>
          <feather-icon icon="ChevronRightIcon" size="18" />
        </template>
      </b-pagination>
    </b-card-body>

    <div>
      <vue-html2pdf
        :show-layout="false"
        :float-layout="true"
        :enable-download="true"
        :preview-modal="true"
        :paginate-elements-by-height="1400"
        filename="ConsigPrev - Relatório"
        :pdf-quality="2"
        :manual-pagination="false"
        pdf-format="a4"
        pdf-orientation="landscape"
        pdf-content-width="1125px"
        @hasStartedGeneration="hasStartedGeneration()"
        @hasGenerated="hasGenerated($event)"
        ref="html2Pdf"
      >
        <section slot="pdf-content">
          <b-card-code>
            <b-table id="tableGRPEPDF" :head-variant="'light'" :items="items" :fields="fieldsPDF"></b-table>
          </b-card-code>
        </section>
      </vue-html2pdf>
    </div>
  </b-card>
</template>

<script>
  import {
    BCard,
    BPagination,
    BTab,
    BCardBody,
    BFormGroup,
    BFormSelect,
    BTable,
    BSpinner,
    BBadge,
    BRow,
    BCol,
    BButton,
    BForm,
    BDropdown,
    BDropdownItem,
  } from 'bootstrap-vue'
  import useJwt from '@/auth/jwt/useJwt'
  import moment from 'moment'
  import Cleave from 'vue-cleave-component'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import Ripple from 'vue-ripple-directive'
  import { formatarData, formatarDataGlobal } from '@/libs/utils'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required } from '@validations'
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import VueHtml2pdf from 'vue-html2pdf'

  export default {
    components: {
      BCard,
      BPagination,
      BTab,
      BCardBody,
      BFormGroup,
      BFormSelect,
      BTable,
      BSpinner,
      BBadge,
      BRow,
      BCol,
      BButton,
      BForm,
      Cleave,
      ToastificationContent,
      ValidationProvider,
      ValidationObserver,
      VueHtml2pdf,
      BDropdown,
      BDropdownItem,
      BCardCode,
      formatarData,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        required,
        pesquisar: {
          tipoOpcoes: 1,
          descricao: '',
          perPage: 10,
          currentPage: 1,
          dataInicial: '',
          dataFinal: '',
        },
        cleaveDate: {
          date: true,
          delimiter: '/',
          datePattern: ['d', 'm', 'Y'],
        },
        formatarData,
        isBusy: true,
        acesso: {},
        pageOptions: [10, 25, 50, 100],
        totalRows: 1,
        sortBy: '',
        sortDesc: false,
        sortDirection: 'asc',
        fieldsPDF: [
          {
            key: 'createdAt',
            label: 'Criado em',
            formatter: (value, key, item) => {
              return formatarData(value)
            },
          },
          { key: 'nome', label: 'Nome do Órgão' },
          { key: 'cnpj', label: 'CNPJ' },
          {
            key: 'responsavelNome',
            label: 'Nome do Responsável',
          },
        ],
        fields: [
          {
            key: 'createdAt',
            label: 'Criado em',
            formatter: (value, key, item) => {
              return formatarData(value)
            },
          },
          { key: 'nome', label: 'Nome do Órgão', sortable: true },
          { key: 'cnpj', label: 'CNPJ', sortable: true },
          {
            key: 'responsavelNome',
            label: 'Nome do Responsável',
            sortable: true,
          },
        ],
        items: [],
        role: [
          {
            master: 'success',
            admin: 'primary',
            user: 'warning',
          },
        ],
      }
    },
    mounted() {
      // this.carregarGrid();
    },
    methods: {
      isDateValid(data) {
        var Title
        const day = moment(new Date(), 'DD-MM-YYYY').format('YYYY-MM-DD')
        const validDate =
          data == ''
            ? ''
            : /^(((0[1-9]|[12][0-9]|3[01])([-.\/])(0[13578]|10|12)([-.\/])(\d{4}))|(([0][1-9]|[12][0-9]|30)([-.\/])(0[469]|11)([-.\/])(\d{4}))|((0[1-9]|1[0-9]|2[0-8])([-.\/])(02)([-.\/])(\d{4}))|((29)(\.|-|\/)(02)([-.\/])([02468][048]00))|((29)([-.\/])(02)([-.\/])([13579][26]00))|((29)([-.\/])(02)([-.\/])([0-9][0-9][0][48]))|((29)([-.\/])(02)([-.\/])([0-9][0-9][2468][048]))|((29)([-.\/])(02)([-.\/])([0-9][0-9][13579][26])))$/.test(
                data,
              )
            ? true
            : false
        if (validDate) {
          const insertData = moment(data, 'DD-MM-YYYY').format('YYYY-MM-DD')
          if (insertData > day) {
            ;(Title = 'Erro: A data inserida é maior que a data atual. Pesquisa inválida. '), this.msgError(Title)
          } else {
            return true
          }
        } else {
          ;(Title = 'Erro: A data inserida não é válida. '), this.msgError(Title)
        }
      },
      msgError(Title) {
        this.$toast(
          {
            component: ToastificationContent,
            props: {
              title: Title,
              icon: 'BellIcon',
              variant: 'danger',
            },
          },
          {
            position: 'top-right',
            timeout: 8000,
          },
        )
      },
      carregarGrid() {
        this.isBusy = true
        this.pesquisar = {
          ...this.pesquisar,
          dataInicial: this.pesquisar.dataInicial != null ? this.pesquisar.dataInicial : null,
          dataFinal: this.pesquisar.dataFinal != null ? this.pesquisar.dataFinal : null,
        }
        useJwt
          .pesquisar('cadastro/orgao/GetRelatorio', {
            ...this.pesquisar,
            institutoSelecionado: this.userData.institutoSelecionado,
          })
          .then((response) => {
            this.isBusy = !this.isBusy
            this.items = response.data.dados
            this.totalRows = response.data.total
          })
          .catch((error) => {
            console.error(error)
          })
      },
      goPesquisar() {
        this.$refs.formPesquisar.validate().then((success) => {
          if (success) {
            if (this.isDateValid(this.pesquisar.dataInicial) && this.isDateValid(this.pesquisar.dataFinal)) {
              this.isBusy = true

              useJwt
                .pesquisar('cadastro/orgao/GetRelatorio', {
                  dataInicial: this.pesquisar.dataInicial ? formatarDataGlobal(this.pesquisar.dataInicial) : null,
                  dataFinal: this.pesquisar.dataFinal ? formatarDataGlobal(this.pesquisar.dataFinal) : null,
                  institutoSelecionado: this.userData.institutoSelecionado,
                })
                .then((response) => {
                  this.isBusy = !this.isBusy
                  this.items = response.data.dados
                  this.totalRows = response.data.total
                })
                .catch((error) => {
                  console.error(error)
                })
            }
          }
        })
      },
      exportToPDF() {
        if (this.items.length > 0) {
          this.$refs.html2Pdf.generatePdf()
        } else {
          s
          this.$message.error('Não há dados para exportar')
        }
      },
      exportToExcel() {
        var tab_text = "<table border='2px'>"
        var j = 0
        var tab = document.getElementById('tableGRPEPDF')

        for (j = 0; j < tab.rows.length; j++) {
          tab_text = tab_text + tab.rows[j].innerHTML + '</tr>'
          tab_text = tab_text.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
        }

        tab_text = tab_text + '</table>'
        tab_text = tab_text.replace(/<A[^>]*>|<\/A>/g, '') //remove if u want links in your table
        tab_text = tab_text.replace(/<img[^>]*>/gi, '') // remove if u want images in your table
        tab_text = tab_text.replace(/<input[^>]*>|<\/input>/gi, '') // reomves input params

        var ua = window.navigator.userAgent
        var msie = ua.indexOf('MSIE ')
        var sa
        if (msie > 0 || !!navigator.userAgent.match(/Trident.*rv\:11\./)) {
          // If Internet Explorer
          txtArea1.document.open('txt/html', 'replace')
          txtArea1.document.write(tab_text)
          txtArea1.document.close()
          txtArea1.focus()
          sa = txtArea1.document.execCommand('SaveAs', true, 'Relatorio.xlsx')
        } //other browser not tested on IE 11
        else sa = window.open('data:application/vnd.ms-excel,' + encodeURIComponent(tab_text))

        return sa
      },
    },
  }
</script>

<style></style>
